import { Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { ApolloProvider } from '@apollo/client'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { SnackbarProvider } from 'notistack'
import { theme, getDataClient, env } from 'config'
import modules from 'modules'
import {
  AuthProvider,
  PageLoader,
  Layout,
  ConfirmationContextProvider,
} from 'shared/components'
import { useCurrentUser } from 'shared/hooks'
import {} from 'shared/components'
import useLocale from 'shared/hooks/use-locale'
import AuthLoader from 'shared/components/AuthLoader'
import { GoogleOAuthProvider } from '@react-oauth/google'

const App = () => {
  const user = useCurrentUser()
  const locale = useLocale()
  const dataClient = getDataClient(user.auth)

  return (
    <AuthProvider value={user}>
      <ApolloProvider client={dataClient}>
        <SnackbarProvider>
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
              <CssBaseline />
              <AuthLoader user={user}>
                <Suspense fallback={<PageLoader />}>
                  <GoogleOAuthProvider
                    clientId={env.googleClientId}
                    onScriptLoadError={onScriptLoadError}
                    onScriptLoadSuccess={onScriptLoadSuccess}
                  >
                    <ConfirmationContextProvider>
                      <BrowserRouter>
                        <Layout routes={modules} />
                      </BrowserRouter>
                    </ConfirmationContextProvider>
                  </GoogleOAuthProvider>
                </Suspense>
              </AuthLoader>
            </LocalizationProvider>
          </ThemeProvider>
        </SnackbarProvider>
      </ApolloProvider>
    </AuthProvider>
  )
}

export default App

//Todo: handle error
const onScriptLoadError = () => {
  console.error('onScriptLoadError')
}

//Todo: handle success ?
const onScriptLoadSuccess = () => {
  console.error('onScriptLoadSuccess')
}
